import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/notice.css"

const notice3 = () => (
  <Layout>
    <SEO title="Notice" />
    <div className="mainContent">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader">
            공고 / 공지
          </div>
          <div className="noticeBody">
            <div className="titleArea">
              <div className="title">[인증] ICT 규제 샌드박스 임시허가 부여 안내</div>
              <div className="date">2020-08-07</div>
            </div>
            <div className="textArea">
              <div className="contentsArea">
                <div className="textDesc">
                  <p>
                    안녕하세요.<br />
                    스테이지파이브입니다.<br /><br />

                    {`<정보통신 진흥 및 융합 활성화 등에 관한 특별법>`} 제37조 제3항 및 동법 시행령 제40조제6항에 따른 신규 정보통신융합등 기술・서비스 임시허가 부여 사실을 아래와 같이 공지합니다.<br /><br />

                    1) 상세 내용<br />
                    - 제목 : 비대면 이동통신 가입 서비스<br />
                    - 기간 : 과기정통부 장관이 부과한 조건을 이행하고 책임보험 등에 가입한 사실을 확인 받은 날로부터 2년 (2020년 8월 11일 ~ 2022년 8월 10일)<br />
                    - 적용 서비스 : 모바일 메신저(카카오톡)의 앱인 앱 형태로 제공하는 비대면 이동 통신 가입(KT·알뜰폰) 채널을 이용하여 카카오페이 인증서를 발급받아 편리하게 본인확인 절차를 진행하고 휴대전화를 개통할 수 있는 서비스<br /><br />

                    2) 사용자 보호 방안<br />
                    {`<정보통신 진흥 및 융합 활성화 등에 관한 특별법>`} 제 37조 제8항 및 동법 시행령 제42조에 따른 책임보험 가입<br /><br />

                    감사합니다.<br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default notice3
